import * as React from 'react'
import classnames from 'classnames'
import empower from '../../../data/images/charities/EMpower_Logo_White_RGB.png'
import halo from '../../../data/images/charities/HaloLogoWhite.png'
import tusk from '../../../data/images/charities/Tusk-white.png'

import { partyHeader, heading, charities, headingCharity, logoEmpower, logoHalo, logoTusk } from './PartyHeader.module.scss'

const PartyHeader = ({ classNames }) => {
  return (
    <div className={classnames(partyHeader, classNames)}>
      <h3 className={heading}>EMSO HOLIDAY COCKTAIL PARTY</h3>
      <h3 className={headingCharity}>In aid of charity</h3>
      <div className={charities}>
        <a href="https://empowerweb.org/" target="_blank">
          <img src={empower} className={logoEmpower} alt="Empower Logo" />
        </a>
        <a href="https://www.halotrust.org/" target="_blank">
          <img src={halo} className={logoHalo} alt="Halo Logo" />
        </a>
        <a href="https://www.tusk.org/" target="_blank">
          <img src={tusk} className={logoTusk} alt="Tusk Logo" />
        </a>
      </div>
    </div>
  )
}

export default PartyHeader
