import Layout from '../../layouts/Layout'
import { Helmet } from 'react-helmet'
import bgImage from '../images/thankyou-background.jpeg'
import * as React from 'react'
import PartyHeader from '../../components/ThankYou/PartyHeader/PartyHeader'
import AnimateEntrance from '../../components/Esg/AnimateEntrance'
import NextSection from '../../components/NextSection/NextSection'
import { QuizGallery, PhotoBoothGallery } from '../../components/Gallery/ImageLists/thankyou-2021/ThankYouGalleries'

import {
  pages,
  introWrapper,
  mainSection,
  background,
  backgroundGradient,
  quizSection,
  bottomSectionTopInner,
  stats,
  mainStats,
  subStats,
  galleries,
  partyWrapper,
} from './thank-you-2021.module.scss'

const ThankYou2021 = () => {
  return (
    <Layout className={pages} blueFooter={false} showNavigation={false} isNonCompliantPage>
      <Helmet>
        <title>Thank You - Emso</title>
        <meta property="og:title" content="Thank You" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://www.emso.com${bgImage}`} />
        <meta name="description" content="Emso Thank You 2021" />
      </Helmet>

      <section className={mainSection}>
        <div className={background} style={{ opacity: 1, backgroundImage: `url(${bgImage})` }} />
        <div className={backgroundGradient} />
        <AnimateEntrance>
          <div className={introWrapper}>
            <div>
              <h1>Charity</h1>
              <h2>Thank you!</h2>
              <p>
                The Emso Team was humbled by your generosity at the recent Emso Holiday Cocktail Party. After nearly two years, we were
                thrilled to see you in person and to have the opportunity to introduce you to three charities that we feel so strongly
                about. We are particularly grateful to those businesses and individuals that donated items to the silent auction. The list
                of auctions items available was truly impressive, and the total raised in the silent auction exceeded all our expectations!
              </p>
              <br />
              <p>
                In total, over <b>USD 79,000</b> was raised to benefit The HALO Trust, Tusk, and EMpower. This total included the Land Mine
                Clearing ‘Challenge’, which raised USD 12,000 alone and cleared 1.25 hectares in Somaliland of land mines, far exceeding the
                goal we had for the evening! We hope you enjoyed learning about each of the charities and the incredible work they all do.
              </p>
              <br />
              <p>
                We know some of you may not have been able to attend on the night or perhaps may have been distracted by the photo booth
                (see photo gallery below!), but if you would like to make a donation to any of the charities directly, please use the
                following links:
              </p>
              <li>
                <a href="https://donorbox.org/halotrust-emso-2021" target="_blank">
                  The HALO Trust
                </a>
              </li>
              <li>
                <a href="https://support.tusk.org/donate" target="_blank">
                  Tusk
                </a>
              </li>
              <li>
                <a href="https://donatenow.networkforgood.org/1407112" target="_blank">
                  EMpower
                </a>
              </li>
              <br />
              <br />
              <p>We wish you and your families a wonderful holiday season and all the best for the start of 2022!</p>
            </div>
            <PartyHeader classNames={partyWrapper} />
          </div>
        </AnimateEntrance>
      </section>
      <NextSection />
      <div className={quizSection}>
        <div className={bottomSectionTopInner}>
          <div className={galleries}>
            <AnimateEntrance>
              <QuizGallery />
            </AnimateEntrance>
            <AnimateEntrance>
              <PhotoBoothGallery />
            </AnimateEntrance>
          </div>
          <AnimateEntrance>
            <div className={stats}>
              <div className={mainStats}>
                <h1>With your help and support, the event raised over</h1>
                <h2>$79,000</h2>
              </div>
            </div>
            <div className={subStats}>
              <div>
                <h1>50 plots of 250 sq meters were cleared of landmines in Somaliland</h1>
                <h2>50</h2>
              </div>
              <div>
                <h1>Which equates to 1.25 hectares of land cleared</h1>
                <h2>1.25</h2>
              </div>
            </div>
          </AnimateEntrance>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou2021
